import React, { useState, useEffect } from "react";
import PropTypes from 'prop-types';
import Container from 'common/src/components/UI/Container';
import CheckBox from 'common/src/components/Checkbox';
import Button from 'common/src/components/Button';
import Input from 'common/src/components/Input';
import Image from 'common/src/components/Image';
import Text from 'common/src/components/Text';
import Box from 'common/src/components/Box';

import SectionWrapper, {  
  Content,
  MobileBannerWrapper,
  DesktopBannerWrapper
} from './contactForm.style';

const GlobalBannerAd = ({ row, col, bannerObj }) => {
  const [ bannersData, setBannersData ] = useState(
      { 
        isEmpty : true, 
        bannerImageMobileUrl : "", 
        bannerImageDesktopUrl : "",
        bannerTargetUrl : ""
      });

  useEffect(onClientLoad, []);

  const handleOnBannerClick = (e) => {
    e.preventDefault();

    window.open(bannersData.bannerTargetUrl, '_blank');
  };
  
  function onClientLoad() {    
    // Post review to the API for submission.
    // https://developer.mozilla.org/en-US/docs/Web/API/Fetch_API/Using_Fetch
    fetch(`/api/get-ads`,
      {
        method: 'GET',        
        headers: { 'Content-Type': 'application/json' }        
      })
      .then(response => {
          // check for 200-299 status codes
          if (!response.ok) {
            console.log('Network response was not ok');
            return;
          }

          return response.json();
      }) // parse JSON from request
      .then(resultData => 
      {
        console.log(resultData);
        setBannersData(resultData);
      })
      .catch((error) => {
        console.error('Unable to connect due to this error:', error);       
      });
  };

    return (
     <>
      {(!bannersData.isEmpty &&
        <SectionWrapper id="bannerAd">
          <Container>   
            <a href="#" onClick={handleOnBannerClick}>
              <DesktopBannerWrapper>              
                  <Image     
                          alt="Banner Ad"                      
                          src={bannersData.bannerImageDesktopUrl}
                      />   
                  <Text as="span" content="Paid Ad" />             
              </DesktopBannerWrapper>     
              <MobileBannerWrapper>
                  <Image  
                          alt="Banner Ad"            
                          src={bannersData.bannerImageMobileUrl}                          
                      />
                  <Text as="span" content="Paid Ad" />
              </MobileBannerWrapper>
            </a>
          </Container> 
        </SectionWrapper> )}
    </>
    );
}

// DonateSection style props
GlobalBannerAd.propTypes = {
    row: PropTypes.object,
    col: PropTypes.object,
    textStyle: PropTypes.object,
  };
  
  // DonateSection default style
GlobalBannerAd.defaultProps = {
    // DonateSection row default style
    row: {
      flexBox: true,
      flexWrap: 'wrap',
      ml: '-15px',
      mr: '-15px',
    },
    // DonateSection col default style
    col: {
      width: ['100%', '50%', '50%'],
      pl: '15px',
      pr: '15px',
      mb: '30px',
    },
  };

export default GlobalBannerAd;
