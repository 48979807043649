import styled from 'styled-components';
import { rgba } from 'polished';
import { themeGet } from '@styled-system/theme-get';
import mapImage from 'common/src/assets/image/charity/map-alt.png';

const SectionWrapper = styled.div`
  width: 100%;  
  background-color: gray;
  padding: 20px;
`;

export const MobileBannerWrapper = styled.div`
  align-items: center;
  flex-wrap: wrap;
  position: relative;

  @media screen and (min-width: 475px) {
    display: none
  }

  img { margin: auto; }

  span { 
    position: absolute; 
    right: 0px;
    bottom: 0px;
    font-size: 12px;
    background-color: black;
    color: white;
    margin: 0px;
  }
`;

export const DesktopBannerWrapper = styled.div`
  align-items: center;
  flex-wrap: wrap;
  margin: auto;
  position: relative;

  @media screen and (max-width: 475px) {
    display: none
  }

  img { margin: auto; }

  span { 
    position: absolute; 
    right: 0px;
    bottom: 0px;
    font-size: 12px;
    background-color: black;
    color: white;
    margin: 0px;
  }
`;

export default SectionWrapper;
