import React, { useState, useEffect } from "react";
import Image from 'gatsby-image';
import PropTypes from 'prop-types';
import Box from 'common/src/components/Box';
import Text from 'common/src/components/Text';
import Heading from 'common/src/components/Heading';
import Container from 'common/src/components/UI/Container';
import GlideCarousel from 'common/src/components/GlideCarousel';
import GlideSlide from 'common/src/components/GlideCarousel/glideSlide';
import Button from 'common/src/components/Button';

import {
  TestimonialSectionWrapper,
  TestimonialSlideWrapper,
  TestimonialItem,
  TestimonialMeta,
  AuthorInfo,
  AuthorImage,
} from './testimonial.style';

const TestimonialSection = ({
  sectionWrapper,
  secTitleWrapper,
  secText,
  secHeading,
  reviewTitle,
  review,
  name,
  designation,
  buttonWrapper,
  outlineBtnStyle,
  confiablePageUrl,
  confiableMerchantId
}) => {

  const [ reviewsData, setReviewsData ] = useState({ isEmpty : true, sampleReviews : [] });

  const carouselOptions = {
    type: 'carousel',
    autoplay: 6000,
    perView: 2,
    gap: 30,
    animationDuration: 800,
    breakpoints: {
      990: {
        perView: 1,
      },
    },
  };
  
  useEffect(onClientLoad, []);

  const handleOnWriteTestimonial = () => {
    window.open(confiablePageUrl, '_blank');
  };

  const handleOnReadTestimonials = () => {
    window.open(confiablePageUrl, '_blank');
  };
  
  function onClientLoad() {
    
    if(!confiableMerchantId || confiableMerchantId.length == 0) 
    {
      return;
    }

    // Post review to the API for submission.
    // https://developer.mozilla.org/en-US/docs/Web/API/Fetch_API/Using_Fetch
    fetch(`/reviews/${confiableMerchantId}/badge.data.json`,
      {
        method: 'GET',        
        headers: { 'Content-Type': 'application/json' }        
      })
      .then(response => {
          // check for 200-299 status codes
          if (!response.ok) {
            console.log('Network response was not ok');
            return;
          }

          return response.json();
      }) // parse JSON from request
      .then(resultData => 
      {
        console.log(resultData);
        setReviewsData(resultData);   
      })
      .catch((error) => {
        console.error('Unable to connect due to this error:', error);       
      });
  };

  return (
    <TestimonialSectionWrapper id="testimonials">
      <Container>
        <Box {...secTitleWrapper}>
          <Text {...secText} content="POR WWW.CONFIABLE.CLUB" />
          <Heading
            {...secHeading}
            content="Testimonios Reales"
          />
        </Box>
        {(reviewsData && !reviewsData.isEmpty && reviewsData.sampleReviews) && 
        <TestimonialSlideWrapper>
          <GlideCarousel
            options={carouselOptions}
            carouselSelector="testimonial__slider"
            controls={false}
            bullets={true}
            numberOfBullets={reviewsData.sampleReviews.length}
          >
            <>
              {reviewsData.sampleReviews.map((item, index) => (
                <GlideSlide key={`testimonial-slide-${index}`}>
                  <TestimonialItem>
                    <Heading as="h3" content={item.title} {...reviewTitle} />
                    <Text content={item.review} {...review} />
                    <TestimonialMeta>
                      <AuthorInfo>
                        {/* <AuthorImage>
                          <Image
                            fluid={item.avatar.childImageSharp.fluid}
                            alt={`reviewer-image-${index}`}
                          />
                        </AuthorImage> */}
                        <Box>
                          <Heading as="h3" content={item.authorName} {...name} />
                          <Text content={item.comments} {...designation} />
                        </Box>
                      </AuthorInfo>
                    </TestimonialMeta>
                  </TestimonialItem>
                </GlideSlide>
              ))}
            </>
          </GlideCarousel>
        </TestimonialSlideWrapper>}
        <Box {...buttonWrapper}>
          <Button title="Escribe un Testimonio" onClick={handleOnWriteTestimonial} />
          <Button
                title="Ver Testimonios"
                variant="textButton"
                onClick={handleOnReadTestimonials}   
                {...outlineBtnStyle}              
              />
        </Box>
      </Container>    
    </TestimonialSectionWrapper>
  );
};

TestimonialSection.propTypes = {
  sectionHeader: PropTypes.object,
};

TestimonialSection.defaultProps = {
  sectionWrapper: {
    pt: ['60px', '80px', '90px', '100px', '100px'],
    pb: ['60px', '80px', '90px', '100px', '100px'],
  },
  secTitleWrapper: {
    mb: ['40px', '60px'],
  },
  secText: {
    as: 'span',
    display: 'block',
    textAlign: 'center',
    fontSize: '14px',
    letterSpacing: '0.15em',
    fontWeight: '700',
    color: '#ff4362',
    mb: '12px',
  },
  secHeading: {
    textAlign: 'center',
    fontSize: ['20px', '24px', '36px', '36px'],
    fontWeight: '700',
    color: '#0f2137',
    letterSpacing: '-0.025em',
    mb: '0',
    ml: 'auto',
    mr: 'auto',
    lineHeight: '1.12',
    width: '500px',
    maxWidth: '100%',
  },
  reviewTitle: {
    fontSize: ['15px', '16px'],
    fontWeight: '500',
    color: '#343d48',
    lineHeight: '1.5',
    mb: '13px',
  },
  review: {
    fontSize: ['16px', '19px'],
    fontWeight: '300',
    color: '#343d48',
    lineHeight: '1.7',
    mb: 0,
  },
  name: {
    fontSize: ['14px', '16px'],
    fontWeight: '500',
    color: '#0f2137',
    letterSpacing: '-0.025em',
    mb: '8px',
  },
  designation: {
    fontSize: ['12px', '14px'],
    color: '#6f7a87',
    mb: 0,
  },
  buttonWrapper: {
    flexBox: true,
    justifyContent: 'center',
    marginTop: '15px'
  },
  outlineBtnStyle: {
    minWidth: '156px',
    fontSize: '16px',
    fontWeight: '500',
    color: '#0f2137',
    colors: 'secondary',
  }
};

export default TestimonialSection;
