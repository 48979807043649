import React, { useState, useEffect } from "react"
import PropTypes from 'prop-types';
import Container from 'common/src/components/UI/ContainerTwo';
import CheckBox from 'common/src/components/Checkbox';
import Button from 'common/src/components/Button';
import Input from 'common/src/components/Input';
import Image from 'common/src/components/Image';
import Text from 'common/src/components/Text';
import Box from 'common/src/components/Box';
import { useForm } from "react-hook-form";

import SectionWrapper, {
  FooterInner,
  Content,
  SubscriptionForm,
  SubmitContactForm,
  SubmitContactThankYou,
  ContentArea,
  Heading,
  HeadingThankYou
} from './contactForm.style';


const ContactForm = ({ internalDomainKey, row, col, emailText, phoneText, addressInfoObj, scheduleText }) => {

  const { handleSubmit, register, errors } = useForm();
  const [ isSubmitted, setIsSubmitted ] = useState(false);
  const [ submitError, setSubmitError ] = useState(false);

  const onSubmit = values => 
  {
    console.log(values);

    const postData = {
      name : values.name,
      email : values.email,
      message : values.message,
      subject : "",
      phoneNumber : "",
      internalDomainKey : internalDomainKey
    };

    var postBody = JSON.stringify(postData);      
    console.log(postBody);

    // Post review to the API for submission.
    // https://developer.mozilla.org/en-US/docs/Web/API/Fetch_API/Using_Fetch
    fetch(`api/submit-contact`,
      {
        method: 'POST',
        cache: 'no-cache',
        headers: { 'Content-Type': 'application/json' },
        body: postBody 
      })
      .then(response => {
          // check for 200-299 status codes
          if (!response.ok) {
            console.log('Network response was not ok');
            setSubmitError(true)
            return;
          }

          setIsSubmitted(true);
          return response;
      }) // parse JSON from request
      .then(resultData => 
      {
          console.log(resultData)        
      })
      .catch((error) => {
        console.error('Unable to connect due to this error:', error);
        setSubmitError(true);
      })
  }

    return (
    <SectionWrapper id="contact">
      <Container>
        <Box className="row" {...row}>
          <Box className="col" {...col}>
            <ContentArea>
              <Heading>
                ¿Como te podemos <span>ayudar</span>?
              </Heading>
              <Text content="Sin ningún compromiso acercate a nosotros para que conozcas nuestros servicios. Estamos disponible por teléfono, email y chat." />
                <ul style={{ color : "white", marginLeft: "10px", lineHeight: "2"}}>
                    {emailText && <li><b>Email:</b> {emailText}</li>}
                    {phoneText && <li><b>Teléfono:</b> {phoneText}</li>}
                    {scheduleText && <li><b>Horario:</b> {scheduleText}</li>}
                    {addressInfoObj && 
                        <>
                        <li><b>Dirección:</b></li>
                        <li>{addressInfoObj.addressLine1}</li>
                        {addressInfoObj.addressLine2 && <li>{addressInfoObj.addressLine2}</li>}
                        <li>{addressInfoObj.addressCity}, {addressInfoObj.addressState}</li>
                        <li>{addressInfoObj.addressCountry} {addressInfoObj.addressZipCode} </li>
                        </>
                    }
                </ul>

            </ContentArea>
          </Box>
          <Box className="col" {...col}>
          {!isSubmitted &&
            <SubmitContactForm data-netlify="false" onSubmit={handleSubmit(onSubmit)}>
                <div>
                <Input
                    displayLabel={false}
                    label="email"            
                    inputType="email"
                    placeholder="Email"
                    iconPosition="left"
                    aria-label="email"
                    inputRef={register({
                      required: "Tu correo es requerido para verificar tu identidad.",  
                      pattern: {
                        value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
                        message: "Tu correo debe ser válido."
                      }                      
                    })}
                />

                <Input
                     displayLabel={false}
                     label="name"     
                    inputType="text"
                    placeholder="Nombre(s)"
                    iconPosition="left"
                    aria-label="name"
                    inputRef={register({
                      required: "Tu nombre es requerido para verificar tu identidad."                                           
                    })}
                />

                <Input
                     displayLabel={false}
                     label="message"     
                    inputType="textarea"
                    placeholder="Mensaje"
                    iconPosition="left"
                    aria-label="name"
                    inputRef={register({
                      required: "El mensaje que deseas enviar."                                           
                    })}
                />

                <Button title="Enviar Mensaje" type="submit" />
                </div>
                {/*<CheckBox
                id="remember"
                htmlFor="remember"
                labelText="Don’t provide any promotional message."
                />*/}
            </SubmitContactForm>}
            {isSubmitted &&
                <SubmitContactThankYou>
                  <HeadingThankYou>
                    !Tu mensaje ha sido enviado!
                  </HeadingThankYou>
                  <Text content="Gracias por el interes en contactarnos. Nos pondremos en contacto a la brevedad posible." />
                </SubmitContactThankYou>
              }
          </Box>
          </Box>
          
      </Container>      
    </SectionWrapper>
    );
}

// DonateSection style props
ContactForm.propTypes = {
    row: PropTypes.object,
    col: PropTypes.object,
    textStyle: PropTypes.object,
  };
  
  // DonateSection default style
ContactForm.defaultProps = {
    // DonateSection row default style
    row: {
      flexBox: true,
      flexWrap: 'wrap',
      ml: '-15px',
      mr: '-15px',
    },
    // DonateSection col default style
    col: {
      width: ['100%', '50%', '50%'],
      pl: '15px',
      pr: '15px',
      mb: '30px',
    },
  };

export default ContactForm;
